var __create = Object.create;
var __getProtoOf = Object.getPrototypeOf;
var __defProp = Object.defineProperty;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __toESM = (mod, isNodeMode, target) => {
  target = mod != null ? __create(__getProtoOf(mod)) : {};
  const to = isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target;
  for (let key of __getOwnPropNames(mod))
    if (!__hasOwnProp.call(to, key))
      __defProp(to, key, {
        get: () => mod[key],
        enumerable: true
      });
  return to;
};
var __commonJS = (cb, mod) => () => (mod || cb((mod = { exports: {} }).exports, mod), mod.exports);
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, {
      get: all[name],
      enumerable: true,
      configurable: true,
      set: (newValue) => all[name] = () => newValue
    });
};

// src/framework/theme/mantine-passthroughs.ts
import {
useMantineTheme
} from "@mantine/core";

// src/framework/theme/ThemeProvider.tsx
import {
MantineProvider
} from "@mantine/core";
import"@mantine/core/styles.css";
import {ModalsProvider} from "@mantine/modals";
import {Notifications} from "@mantine/notifications";
import {
jsx,
jsxs
} from "react/jsx-runtime";
var makeVariablesResolver = (theme) => {
  if (!theme) {
    return () => ({
      variables: {},
      light: {},
      dark: {}
    });
  }
  const colors = theme.colors ?? {};
  const lightBase = {
    ["--mantine-color-high-contrast-background"]: "var(--mantine-color-gray-8)",
    ["--mantine-color-high-contrast-foreground"]: "var(--mantine-color-white)",
    ["--mantine-color-high-contrast-inverted-background"]: "var(--mantine-color-white)",
    ["--mantine-color-high-contrast-inverted-foreground"]: "var(--mantine-color-gray-8)"
  };
  const light = Object.entries(colors).reduce((acc, [name, shades]) => {
    if (shades?.length !== 10) {
      return acc;
    }
    return {
      ...acc,
      [`--mantine-color-${name}-background-hover`]: shades[0],
      [`--mantine-color-${name}-background`]: shades[7],
      [`--mantine-color-${name}-foreground`]: shades[2],
      [`--mantine-color-${name}-text`]: shades[6],
      [`--mantine-color-${name}-light`]: `var(--mantine-color-${name}-0)`
    };
  }, lightBase);
  const darkBase = {
    ["--mantine-color-high-contrast-background"]: "var(--mantine-color-white)",
    ["--mantine-color-high-contrast-foreground"]: "var(--mantine-color-gray-8)",
    ["--mantine-color-high-contrast-inverted-background"]: "var(--mantine-color-gray-8)",
    ["--mantine-color-high-contrast-inverted-foreground"]: "var(--mantine-color-white)"
  };
  const dark = Object.entries(colors).reduce((acc, [name, shades]) => {
    if (shades?.length !== 10) {
      return acc;
    }
    return {
      ...acc,
      [`--mantine-color-${name}-background-hover`]: shades[9],
      [`--mantine-color-${name}-background`]: shades[2],
      [`--mantine-color-${name}-foreground`]: shades[7],
      [`--mantine-color-${name}-text`]: shades[2]
    };
  }, darkBase);
  return () => ({
    variables: {},
    light,
    dark
  });
};
var ThemeProvider = ({
  theme = {},
  children,
  ...restProps
}) => {
  const cssVariablesResolver = makeVariablesResolver(theme);
  return /* @__PURE__ */ jsxs(MantineProvider, {
    ...restProps,
    theme,
    cssVariablesResolver,
    children: [
      /* @__PURE__ */ jsx(Notifications, {}),
      /* @__PURE__ */ jsx(ModalsProvider, {
        children
      })
    ]
  });
};

// src/framework/theme/util/create-theme.ts
import {
defaultVariantColorsResolver,
createTheme as mantineCreateTheme,
rgba
} from "@mantine/core";

// __style_helper__
function injectStyle(text) {
  if (typeof document !== "undefined") {
    const styleTag = document.getElementById("bun_lightningcss");
    if (styleTag) {
      const node2 = document.createTextNode(text);
      styleTag.appendChild(node2);
      return;
    }
    var style = document.createElement("style");
    style.id = "bun_lightningcss";
    var node = document.createTextNode(text);
    style.appendChild(node);
    document.head.appendChild(style);
  }
}

// src/components/notification/notification.module.css
injectStyle(".uYXYeW_root:before{content:none}.uYXYeW_root{background-color:var(--mantine-color-gray-9);padding:var(--mantine-spacing-xxxs)var(--mantine-spacing-xxxs)var(--mantine-spacing-xxxs)var(--mantine-spacing-xs)}.uYXYeW_title,.uYXYeW_description,.uYXYeW_closeButton{color:var(--mantine-color-white)}.uYXYeW_description{padding-block:var(--mantine-spacing-xxs)}.uYXYeW_closeButton{--cb-size:2.5rem;align-self:start;--cb-icon-size:1.5rem!important}:where([data-mantine-color-scheme=light]) .uYXYeW_closeButton:hover{background-color:var(--mantine-color-gray-7)}");
var notification_module_default = { closeButton: "uYXYeW_closeButton", root: "uYXYeW_root", description: "uYXYeW_description", title: "uYXYeW_title" };

// src/framework/theme/util/storybook.ts
var BASIC_COLOR_VARIANTS = [
  "black",
  "white",
  "currentColor"
];
var COLOR_VARIANTS = [
  "gray",
  "saving",
  "primary",
  "secondary",
  "error",
  "success",
  "warning",
  "info",
  "notice"
];
var COLOR_VARIANTS_SUMMARY = COLOR_VARIANTS.join(" | ");

// src/framework/theme/util/get-style-for-color.ts
var getStyleForColor = (color) => {
  if (color === "inherit") {
    return {
      bg: "inherit",
      c: "currentColor"
    };
  }
  if (!COLOR_VARIANTS.includes(color)) {
    throw new Error('Only colors in the "semantic" palette may be passed to this function');
  }
  return {
    bg: `var(--mantine-color-${color}-background)`,
    c: `var(--mantine-color-${color}-foreground)`
  };
};

// src/framework/theme/util/create-theme.ts
var createTheme = (theme) => {
  return mantineCreateTheme({
    ...theme,
    components: {
      Notification: {
        defaultProps: {
          radius: "xxs"
        },
        classNames: notification_module_default
      }
    },
    other: {
      ...theme.other ?? {},
      fontWeights: {
        bold: 600,
        medium: 500,
        regular: 400
      },
      getStyleForColor,
      getActionableIconColor: (actionable) => {
        const color = actionable ? "primary" : "secondary";
        return getStyleForColor(color).bg;
      },
      getIconColor: (color) => {
        if (color === "black" || color === "white" || color === "currentColor") {
          return color;
        }
        return getStyleForColor(color).bg;
      },
      getTextColor: (color) => {
        return `var(--mantine-color-${color}-text)`;
      }
    },
    variantColorResolver: (input) => {
      const defaultResolvedColors = defaultVariantColorsResolver(input);
      if (input.color === "high-contrast" || input.color === "high-contrast-inverted") {
        switch (input.variant) {
          case "outline":
            return {
              background: "transparent",
              hover: rgba(`var(--mantine-color-${input.color}-background)`, 0.15),
              color: `var(--mantine-color-${input.color}-background)`,
              border: `calc(0.0625rem * var(--mantine-scale)) solid var(--mantine-color-${input.color}-background)`
            };
          case "subtle":
            return {
              background: "transparent",
              hover: rgba(`var(--mantine-color-${input.color}-background)`, 0.15),
              color: `var(--mantine-color-${input.color}-background)`,
              border: "none"
            };
          case "filled":
          default:
            return {
              background: `var(--mantine-color-${input.color}-background)`,
              hover: rgba(`var(--mantine-color-${input.color}-background)`, 0.15),
              color: `var(--mantine-color-${input.color}-foreground)`,
              hoverColor: `var(--mantine-color-${input.color}-background)`,
              border: "none"
            };
        }
      }
      return defaultResolvedColors;
    }
  });
};

// src/framework/theme/util/misc.ts
import {px, rem} from "@mantine/core";
// src/framework/utils/constants.ts
var exports_constants = {};
__export(exports_constants, {
  unicode: () => unicode
});
var unicode = {
  BULLET: "\u2022",
  COPYRIGHT: "\xA9",
  DAGGER: "\u2020",
  DEGREE_C: "\u2103",
  DEGREE_F: "\u2109",
  DOUBLE_DAGGER: "\u2021",
  REGISTERED: "\xAE",
  SECTION: "\xA7",
  TRADEMARK: "\u2122"
};
// src/framework/utils/get-initials/get-initials.ts
var getInitials = (name) => {
  if (name.length === 0) {
    return;
  }
  const parts = name.split(" ");
  if (parts.length > 1) {
    const first = parts.shift();
    const last = parts.pop();
    return `${first?.[0]}${last?.[0]}`.toUpperCase();
  }
  return parts[0][0].toUpperCase();
};
// src/framework/utils/format-date/format-date.ts
var formatDate = (date, options, locale = "en-US") => {
  try {
    return new Intl.DateTimeFormat(locale, options).format(date);
  } catch (error) {
    console.error(`Could not parse date: ${date} for locale: ${locale}`);
  }
};
// src/framework/utils/format-time/format-time.ts
import {useTranslation} from "react-i18next";
var formatTime = (time) => {
  const { t } = useTranslation("pds");
  const [hours, minutes, _seconds] = time.split(":").map(Number);
  const timePeriod = hours >= 12 ? t("timePeriod.pm") : t("timePeriod.am");
  const convertedHours = hours % 12 || 12;
  const paddedMinutes = minutes.toString().padStart(2, "0");
  return `${convertedHours}:${paddedMinutes} ${timePeriod}`;
};
export {
  useMantineTheme as useTheme,
  rem,
  px,
  getStyleForColor,
  getInitials,
  formatTime,
  formatDate,
  createTheme,
  exports_constants as constants,
  ThemeProvider,
  COLOR_VARIANTS_SUMMARY,
  COLOR_VARIANTS,
  BASIC_COLOR_VARIANTS
};

//# debugId=C9C05B4B7E500BEE64756E2164756E21
