import { Caption, FilledCard, Heading2, Stack } from '@phx/design-system';
import { PricingInfo } from '@phx/myphx-lib';
import { useTranslation } from 'react-i18next';

import type {
    CashOfferInfoFragment,
    InsuranceOfferInfoFragment,
} from '../../../graphql/generated/graphql';

export const PickupPricing = ({
    offer,
}: {
    offer: CashOfferInfoFragment | InsuranceOfferInfoFragment;
}) => {
    const { t } = useTranslation();

    return (
        <Stack gap="sm" data-testid="price-quote-card">
            <Heading2>{t('pricing.subHeader')}</Heading2>
            <FilledCard color="gray">
                <PricingInfo
                    option={offer.pricingDetail.option}
                    value={offer.pricingDetail.value}
                    details={offer.pricingDetail.details ?? ''}
                />
            </FilledCard>
            <Caption>{t('pricing.pricingInfo.pricesVary')}</Caption>
        </Stack>
    );
};
