import { useConditionalBackOverride } from '@phx/design-system';
import { useDisclosure } from '@phx/design-system/hooks';
import { useTelemetryContext } from '@phx/instrumentation/client';
import {
    Navigate,
    createSearchParams,
    useNavigate,
    useParams,
} from 'react-router-dom';

import { ShoppingHeader } from '../../../components/cabinet/shopping-header/ShoppingHeader';
import { PatientProductDetails } from '../../../components/preview-details/components/PatientProductDetails';
import { ProviderSelection } from '../../../components/provider-offers';
import { getFragment, getFragments } from '../../../graphql';
import {
    GetPrescriberOrderOffersDocument,
    type GetPrescriberOrderOffersQuery,
} from '../../../graphql/generated/graphql';
import { useNumericSearchParam } from '../../../hooks/use-numeric-search-param';
import { usePatientContext } from '../../../hooks/use-patient-context';
import { type DataHandlerType, QueryLoader } from '../../../loaders';
import { getAbsoluteRoute } from '../../../util';
import { getFeaturedProviderOffers } from '../../../util/alternatives/get-featured-provider-offers';
import { getOffersByProviderId } from '../../../util/offers/get-offers-by-provider-id';
import {
    getPrimaryProvider,
    getProviderOffersByType,
} from '../../../util/provider-offers/provider-offers-utils';

import { LeavePrescriptionWarningModal } from './LeavePrescriptionWarningModal';
import { usePrescriptionInputVariables } from './use-prescription-variables';

export const PrescriptionRoute = () => {
    const Loader = QueryLoader<typeof GetPrescriberOrderOffersDocument>;
    const { prescriptionId } = useParams();
    const { scopedPatientId } = usePatientContext();

    const distance = useNumericSearchParam('within');

    if (!prescriptionId) {
        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const input = usePrescriptionInputVariables(distance, scopedPatientId);

    return (
        <Loader
            query={GetPrescriberOrderOffersDocument}
            queryOptions={{ errorPolicy: 'all' }}
            component={DataHandler}
            variables={{
                prescriberOrderId: prescriptionId,
                input,
            }}
        />
    );
};

const DataHandler: DataHandlerType<typeof GetPrescriberOrderOffersDocument> = ({
    data,
}: {
    data: GetPrescriberOrderOffersQuery;
}) => {
    const navigate = useNavigate();
    const { telemetryInstance } = useTelemetryContext();
    const [isBackWarnOpen, { open, close }] = useDisclosure(false);

    useConditionalBackOverride(open, true);

    if (
        data.prescriberOrder?.__typename !== 'DigitallyReceivedPrescriberOrder'
    ) {
        telemetryInstance.logError({
            source: 'prescription-route',
            error: new Error(
                'Cannot get offers for a non-digital prescription'
            ),
        });

        return <Navigate to={getAbsoluteRoute('error')} />;
    }

    const prescriberOrder = getFragment(data.prescriberOrder);
    const offersConnection = getFragment(data.prescriberOrder.offersConnection);

    const providerOffers =
        offersConnection?.edges.map((edge) => getFragment(edge.node)) ?? [];

    const onSelect = (providerOffersId: string, providerId?: string) => {
        const selectedProviderOffers = providerOffers.find(
            (offer) => offer.id === providerOffersId
        );

        const searchParams = providerId
            ? createSearchParams({ providerId }).toString()
            : '';

        if (selectedProviderOffers) {
            if (
                selectedProviderOffers?.provider.__typename === 'ChainProvider'
            ) {
                const selectedProviderId =
                    providerId ??
                    getPrimaryProvider(selectedProviderOffers)?.id;

                if (selectedProviderId) {
                    const offers = getFragments(selectedProviderOffers.offers);

                    const offersForSelected = getOffersByProviderId(
                        offers,
                        selectedProviderId
                    );

                    if (offersForSelected.length <= 1) {
                        navigate({
                            pathname: getAbsoluteRoute(
                                'cabinet.rxId.providerOffersId.offer.root',
                                {
                                    prescriptionId:
                                        prescriberOrder.drxId ??
                                        prescriberOrder.id,
                                    providerOffersId,
                                    offerId: offersForSelected[0].id,
                                }
                            ),
                            search: searchParams,
                        });
                        return;
                    }
                }
            }

            if (selectedProviderOffers?.offers.length <= 1) {
                const offerFragment = getFragment(
                    selectedProviderOffers.offers[0]
                );
                navigate({
                    pathname: getAbsoluteRoute(
                        'cabinet.rxId.providerOffersId.offer.root',
                        {
                            prescriptionId:
                                prescriberOrder.drxId ?? prescriberOrder.id,
                            providerOffersId,
                            offerId: offerFragment?.id ?? 'unknown',
                        }
                    ),
                    search: searchParams,
                });
                return;
            }
        }

        navigate({
            pathname: getAbsoluteRoute('cabinet.rxId.providerOffersId.root', {
                prescriptionId: prescriberOrder.drxId ?? prescriberOrder.id,
                providerOffersId,
            }),
            search: searchParams,
        });
    };

    const featuredProvider = getFeaturedProviderOffers(offersConnection);

    const providerOfferTypes = getProviderOffersByType(
        offersConnection,
        featuredProvider?.providerOffers.id
    );

    const patient = getFragment(prescriberOrder.patient);

    return (
        <>
            <LeavePrescriptionWarningModal
                isOpen={isBackWarnOpen}
                close={close}
                onLeaveClick={() => navigate(-1)}
            />
            <ProviderSelection
                providerOffersByType={providerOfferTypes}
                onSelect={onSelect}
                header={
                    <ShoppingHeader>
                        <PatientProductDetails
                            patientName={patient.fullName}
                            productDescription={
                                prescriberOrder.productDescription
                            }
                            quantity={prescriberOrder.quantity}
                        />
                    </ShoppingHeader>
                }
                featuredProvider={featuredProvider}
            />
        </>
    );
};
